<template>
  <div class="page-view">
    <div class="wrap-in">
      <span class="back-button" @click="setReturn">
        <font-awesome-icon :icon="faArrowLeft" />
      </span>
      <h2>Coming soon</h2>
      <p>This page is under construction.</p>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
export default {
  components: {
    FontAwesomeIcon
  },
  data:() => {
    return {
      faArrowLeft,
      loading: false
    }
  },
  methods: {
    setReturn() {
      this.$router.go(-1);
    }
  },
}
</script>
<style lang="scss" scoped>
  .wrap-in {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $primary-color;
    position: relative;
    .back-button {
      position: absolute;
      top: 30px;
      left: 30px;
      font-size: 28px;
      cursor: pointer;
    }
    > * {
      color: $white;
    }
    h2 {
      font-weight: bold;
      font-size: 2.4rem;
    }
    p {
      font-size: 1.1em;
    }
  }
</style>